import React, { useState, useEffect, useCallback, useRef } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import { Modal, ModalBody, Spinner, Alert } from 'reactstrap'
import { CloseButton } from 'components/lib/Button'
import { Button } from 'reactstrap'
import { FiDownload, FiMail } from 'react-icons/fi'

// Set worker URL for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`

/**
 * Modal component for previewing PDFs with download and email capabilities
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.toggle - Function to toggle the modal
 * @param {Blob|string} props.pdf - PDF data to display (blob or base64 string)
 * @param {Function} props.download - Function to download the PDF
 * @param {Function} props.email - Function to email the PDF
 * @param {boolean} props.isLoading - Whether the PDF is loading
 * @param {string|null} props.error - Error message to display
 * @param {boolean} props.downloadBusy - Whether a download is in progress
 */
const PdfPreviewModal = ({ 
  isOpen, 
  toggle, 
  pdf, 
  download, 
  email, 
  isLoading = false, 
  error = null, 
  downloadBusy = false 
}) => {
  const [numPages, setNumPages] = useState(null)
  const [loadError, setLoadError] = useState(null)
  const pdfContainerRef = useRef(null);
  
  // Apply custom styles to the container to enhance page break rendering
  useEffect(() => {
    if (pdfContainerRef.current) {
      const style = document.createElement('style');
      style.textContent = `
        .pdf-preview-page {
          margin-bottom: 20px !important;
          box-shadow: 0 2px 5px rgba(0,0,0,0.2) !important;
          page-break-after: always !important;
          break-after: page !important;
        }
        
        .forced-page-break, .forced-area-page-break {
          page-break-after: always !important;
          break-after: page !important;
          margin: 20px 0 !important;
          height: 1px !important;
          background: #f0f0f0 !important;
        }
        
        .area-content[style*="page-break-before:always"] {
          page-break-before: always !important;
          break-before: page !important;
          margin-top: 20px !important;
        }
      `;
      document.head.appendChild(style);
      
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [isOpen]);

  // Reset the error state when the PDF changes or modal opens/closes
  useEffect(() => {
    if (isOpen && pdf) {
      setLoadError(null)
    }
  }, [isOpen, pdf])

  /**
   * Handles successful PDF document loading
   * @param {Object} data - Document data with page count
   */
  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages)
    setLoadError(null)
  }, [])

  /**
   * Handles PDF document loading errors
   * @param {Error} error - Loading error
   */
  const onDocumentLoadError = useCallback((error) => {
    setLoadError('Failed to load the PDF document. Please try again.')
  }, [])

  /**
   * Handles retry after error
   */
  const handleRetry = useCallback(() => {
    setLoadError(null)
    
    // Force reload of the document by closing and reopening the modal
    if (toggle) {
      toggle()
      setTimeout(() => {
        if (toggle) toggle()
      }, 100)
    }
  }, [toggle])

  // Handle modal cleanup on close
  const handleModalClose = useCallback(() => {
    // Clean up state
    setNumPages(null)
    setLoadError(null)
  }, [])

  // Render all pages with enhanced spacing to make page breaks more visible
  const renderAllPages = () => {
    if (!numPages) return null;
    
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <div key={`page-container-${i}`} className="pdf-page-container" style={{
          marginBottom: '30px',
          position: 'relative',
        }}>
          <Page
            className="pdf-preview-page"
            key={`page_${i}`}
            pageNumber={i}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            loading={
              <div style={{ textAlign: 'center', padding: '1rem' }}>
                <Spinner color="primary" size="sm" />
              </div>
            }
            error={
              <div style={{ 
                textAlign: 'center', 
                padding: '1rem',
                color: '#dc3545',
                backgroundColor: '#f8d7da', 
                borderRadius: '0.25rem',
                margin: '0.5rem'
              }}>
                Error rendering page {i}
              </div>
            }
          />
        </div>
      );
    }
    
    return pages;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="big-modal"
      backdrop="static"
      autoFocus={false}
      onClosed={handleModalClose}
      aria-labelledby="pdf-preview-modal-title"
    >
      <ModalBody>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button
              size="sm"
              style={{ marginRight: 6 }}
              onClick={() => {
                if (typeof download === 'function') download()
              }}
              disabled={!download || isLoading || downloadBusy}
              title="Download PDF"
              aria-label="Download PDF"
            >
              {downloadBusy ? (
                <Spinner size="sm" />
              ) : (
                <FiDownload size={18} />
              )}
              <span className="sr-only">Download PDF</span>
            </Button>
            <Button 
              size="sm" 
              color="primary" 
              onClick={() => {
                if (typeof email === 'function') email()
              }}
              disabled={!email || isLoading}
              title="Email PDF"
              aria-label="Email PDF"
            >
              <FiMail size={18} />
              <span className="sr-only">Email PDF</span>
            </Button>
          </div>
          <CloseButton onClick={toggle} aria-label="Close preview" />
        </div>
        
        <div 
          ref={pdfContainerRef}
          style={{ marginTop: 24, height: 600, overflowY: 'auto' }}
          aria-live="polite"
          className="pdf-preview-container"
        >
          {isLoading && (
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              height: '100%'
            }}
            aria-live="assertive"
            role="status"
            >
              <Spinner color="primary" style={{ width: '3rem', height: '3rem', marginBottom: '1rem' }} />
              <div id="pdf-preview-modal-title">Proposal preview is updating...</div>
            </div>
          )}
          
          {error && !isLoading && (
            <Alert color="danger" style={{ marginTop: '20px' }}>
              {error}
              <div style={{ marginTop: '10px' }}>
                <Button size="sm" color="danger" onClick={toggle}>Close</Button>
                <Button 
                  size="sm" 
                  color="primary" 
                  style={{ marginLeft: '10px' }} 
                  onClick={handleRetry}
                >
                  Try Again
                </Button>
              </div>
            </Alert>
          )}
          
          {loadError && !isLoading && !error && (
            <Alert color="warning" style={{ marginTop: '20px' }}>
              {loadError}
              <div style={{ marginTop: '10px' }}>
                <Button size="sm" color="primary" onClick={handleRetry}>
                  Try Again
                </Button>
              </div>
            </Alert>
          )}
          
          {!isLoading && !error && !loadError && pdf && (
            <Document
              className="pdf-preview-document"
              file={pdf}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              loading={
                <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                  <Spinner color="primary" size="sm" />
                  <div style={{ marginTop: '0.5rem' }}>Loading PDF...</div>
                </div>
              }
              error={
                <Alert color="warning" style={{ marginTop: '20px' }}>
                  Failed to load the PDF. The file may be corrupted or too large.
                  <div style={{ marginTop: '10px' }}>
                    <Button size="sm" color="primary" onClick={handleRetry}>
                      Try Again
                    </Button>
                  </div>
                </Alert>
              }
              options={{
                cMapUrl: 'https://unpkg.com/pdfjs-dist@2.6.347/cmaps/',
                cMapPacked: true,
                standardFontDataUrl: 'https://unpkg.com/pdfjs-dist@2.6.347/standard_fonts/'
              }}
            >
              {renderAllPages()}
              
              {numPages > 0 && (
                <div className="pdf-page-counter" style={{ 
                  textAlign: 'center', 
                  margin: '1rem 0', 
                  color: '#666',
                  fontSize: '0.9rem'
                }}>
                  {numPages} {numPages === 1 ? 'page' : 'pages'} total
                </div>
              )}
            </Document>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default PdfPreviewModal
