import React from 'react'

export default ({ element, proposal, renderForPdf }) => {
  proposal = proposal || {}
  const showHeading = element && element.data && element.data.showHeading
  const headingText =
    (element && element.data && element.data.headingText) || 'Scope of Work'
  const textSize = (element && element.data && element.data.textSize) || 12

  const renderTasks = tasks => {
    return tasks.map((task, index) => (
      <tr key={`task-tr-${index}`}>
        <td className="text-left" style={{fontSize: textSize}}>{task.name}</td>
        <td className="text-right" style={{ width: '20%', fontSize: textSize }}>
          {task.frequency}
        </td>
      </tr>
    ))
  }

  const renderSections =
    element &&
    element.data &&
    element.data.taskSections &&
    element.data.taskSections.map((section, index) => (
      <div
        key={`scope-of-work-section-${index}`}
        className="area"
      >
        {section.pageBreak && !renderForPdf && (
          <div style={{
            backgroundColor: "#2e2f31",
            margin: "40px -15px",
            padding: "20px"
          }}>
            <div
              className="custom-page-break"
              style={{
                width: "100%",
                height: "30px",
                padding: "40px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                fontSize: "20px",
                color: "white",
                backgroundColor: "#2e2f31",
                borderTop: "0.1em dashed #ccc",
                borderBottom: "0.1em dashed #ccc"
              }}
            >
              Scope of Work Page Break
              <div style={{ fontSize: "14px", fontStyle: "italic" }}>
                Uncheck Page Break Box inside Scope of Work to remove.
              </div>
              <div style={{ fontSize: "14px", fontStyle: "italic" }}>
                Review the Preview to confirm that your page breaks appear exactly as you intend.
              </div>
            </div>
          </div>
        )}
        <div 
          className="area-content"
          style={section.pageBreak && renderForPdf ? { 
            pageBreakBefore: 'always',
            breakBefore: 'page',
            marginTop: '20px', 
            paddingTop: '15px',
            display: 'block' 
          } : {}}
        >
          <h5 style={{ marginBottom: '5px', fontSize: textSize + 2 }}>
            {section.name || 'No section name'}
          </h5>
          {section.tasks && section.tasks.length > 0 ? (
            <table className="scope-table" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th className="text-left" style={{ fontSize: textSize }}>Task</th>
                  <th className="text-right" style={{ width: '20%', fontSize: textSize }}>
                    Frequency
                  </th>
                </tr>
              </thead>
              <tbody>{renderTasks(section.tasks)}</tbody>
            </table>
          ) : null}
          {section.spacer ? <div style={{ height: section.spacer }} /> : null}
        </div>
      </div>
    ))

  return (
    <div style={{ paddingBottom: 20 }}>
      {showHeading && <h2 className="section-title">{headingText}</h2>}
      <div className="task-section">{renderSections}</div>
    </div>
  )
}
